@fontColor: rgba(0,0,0,.9);

@blue-1: #2457C6;
@blue-2: #1C47A6;
@blue-3: #346AE5;
@blue-4: #4D77A2;
@blue-4-hover: #2F587F;
@blue-5: #BED6F0;
@blue-5-hover: #799EC4;

@grey-1: #F0F0F0;
@grey-2: #E8E8E8;
@grey-3: #F7F7F7; // 0.03
@grey-4: #EDEDED; // 0.07
@grey-5: #E5E5E5; // 0.1

@red-1: #fd3c4f;
@red-2: #e02f41;
@red-3: #FFBFBF;

@green-1: #59b78b;
@green-1-hover: #51AD82;
@green-2: #D4EEB5;

@yellow-1: #f8da79;
@yellow-1-hover: #E3C153;
@yellow-2: #FAF2D9;

@font-family: "Open Sans", sans-serif;

// media
@mobile:  ~'(max-width: 960px)' ;
@tablet: ~'(min-width: 768px) and (max-width: 960px)';
@retina: ~'(-webkit-min-device-pixel-ratio: 2) and (min-width: 320px)';

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

select{
    appearance: none; /* Remove default styles */
    font-size: .85vw;
    border: 1px solid rgba(0,0,0,.1);
    background-color: white;
    cursor: pointer;
    outline: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right .5vw center;
    background-size: 1.2vw;
    width: 100%;
    padding: .4vw .5vw;
    margin-bottom: .5vw;
    font-family: @font-family;

    &:focus {
        border: 1px solid rgba(0,0,0,.3);
    }
}