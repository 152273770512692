* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}
input:focus,
input,
textarea,
textarea:hover,
a,
a:hover,
.animate,
.animate:hover,
.animate,
.animate:hover {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.animate-slow,
.animate-slow:hover {
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
input,
select,
textarea {
  font-family: "Open Sans", sans-serif;
}
.hide {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center {
  margin: 0 auto;
}
.cover-size {
  background-size: cover!important;
}
.rounded {
  border-radius: 0.3vw;
}
.rounded-mini {
  border-radius: 0.18vw;
}
.rounded-full {
  border-radius: 10vw;
}
.section {
  padding: 0 5vw;
  display: flex;
}
.shadow {
  box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.1);
  border: none;
  background: #FFF;
}
.shadow:hover {
  box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.2);
}
.shadow:focus,
.shadow:active {
  border: none;
  outline: none;
}
.button {
  display: flex;
  border: none;
  padding: 1vw 1.5vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  line-height: 100%;
}
.button.full-width {
  width: 100%;
}
.button span {
  font-weight: 700;
  font-size: 0.9vw;
}
.button img,
.button svg {
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.5vw;
}
.button.blue {
  background: #2457C6;
  border: 1px solid #1C47A6;
  color: #FFF;
}
.button.blue:hover {
  background: #1C47A6;
}
.button.dark-grey {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
}
.button.dark-grey svg {
  fill: #000;
}
.button.no-button {
  cursor: default;
}
.button.blue-hover:hover {
  background: #1C47A6 !important;
}
.button.grey {
  background: #F7F7F7;
  border: 1px solid #E8E8E8;
}
.button.grey:hover {
  background: #EDEDED;
  border: 1px solid #E5E5E5;
}
.button.green {
  background: #59b78b;
}
.button.yellow {
  background: #f8da79;
}
.button.yellow:hover {
  background: #E3C153;
}
.button.yellow-border {
  background: #FAF2D9;
  border: 1px solid #f8da79;
}
.button.yellow-border:hover {
  background: #f8da79;
  border: 1px solid #f8da79;
}
.button.red {
  background: #fd3c4f;
  color: #FFF;
  border: 1px solid #e02f41;
}
.button.red:hover {
  background: #e02f41;
}
.button.blue-dark {
  background: #4D77A2;
  color: #FFF;
}
.button.blue-dark:hover {
  background: #2F587F;
}
.button.mini {
  padding: 0 1vw;
  height: 2.4vw;
}
.button.mini span {
  font-size: 0.7vw;
}
.button.mini img,
.button.mini svg {
  width: 0.8vw;
  height: 0.8vw;
}
.button.middle {
  padding: 0 1.2vw;
  height: 3vw;
}
.button.middle span {
  font-size: 0.9vw;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
select {
  appearance: none;
  /* Remove default styles */
  font-size: 0.85vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;
  outline: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5vw center;
  background-size: 1.2vw;
  width: 100%;
  padding: 0.4vw 0.5vw;
  margin-bottom: 0.5vw;
  font-family: "Open Sans", sans-serif;
}
select:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
